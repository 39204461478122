const SupportQuestionCategory = {
  General: 1,
  Bug: 2,
  Feature: 3,
  Partnering: 4
} as const;

type TSupportQuestionCategory = keyof typeof SupportQuestionCategory;

const SupportQuestionCategorySelect = [
  {
    label: 'General',
    value: SupportQuestionCategory.General
  },
  {
    label: 'Bug',
    value: SupportQuestionCategory.Bug
  },
  {
    label: 'Feature',
    value: SupportQuestionCategory.Feature
  },
  {
    label: 'Partnering',
    value: SupportQuestionCategory.Partnering
  }
];

export { SupportQuestionCategory, SupportQuestionCategorySelect, type TSupportQuestionCategory };
