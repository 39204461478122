<template>
  <form
    class="max-w-md w-full"
    @submit="onSubmit"
  >
    <ui-typography
      tag="h1"
      variant="h1"
      class="mb-2.5 lg:mb-12 text-center"
      text="Choose Your Question"
    />
    <div class="grid gap-0 lg:gap-5 lg:mb-7 mb-5">
      <ui-fields-select
        label="Type"
        :options="options"
        name="category_id"
      />
      <ui-fields-text
        name="email"
        label="Email*"
      />
      <ui-fields-textarea
        name="message"
        label="Enter Your Message"
      />
      <ui-fields-file-validate name="attachment" />
    </div>
    <ui-button
      full
      class="lg:mb-5"
      type="submit"
      text="Send"
    />
  </form>
</template>
<script setup lang="ts">
  import { object } from 'yup';
  import { useForm } from 'vee-validate';
  const emit = defineEmits(['success']);

  const options = SupportQuestionCategorySelect;
  const apiRoutes = useApiRoutes();
  const validationFields = useValidationRules();

  const initialValues = {
    category_id: SupportQuestionCategory.General,
    email: '',
    message: '',
    attachment: undefined
  };

  const { handleSubmit } = useForm({
    validationSchema: object({
      email: validationFields.validationEmail(),
      message: validationFields.requiredString()
    }),
    initialValues
  });

  const onSubmit = handleSubmit(
    useSubmitHandler(apiRoutes.support.create, initialValues, {
      onSuccess() {
        emit('success');
      }
    })
  );
</script>
