<template>
  <common-profile-settings-content-wrap :breadcrumbs="breadcrumbs">
    <common-auth-support-form-wrapper />
  </common-profile-settings-content-wrap>
</template>

<script setup lang="ts">
  const breadcrumbs = useDashboardSettingsBreadcrumbs(
    computed(() => [
      {
        to: { name: 'dashboard-settings-support' },
        disabled: false,
        name: 'Social Profiles'
      }
    ])
  );

  useSeoMeta({
    title: 'Support'
  });
</script>
