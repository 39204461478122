<template>
  <ui-parts-form-success-box :is-auth-page="false">
    <template #form="{ onSuccess }">
      <common-forms-auth-support @success="onSuccess" />
    </template>
    <template #success>
      <div class="min-h-96 flex items-center">
        <div class="mb-2.5 text-center">
          <ui-typography
            text="Your message has been sent to our support team!"
            variant="h3"
            tag="h3"
          />
          <ui-typography
            text="We will send a response to your email!"
            variant="h3"
            tag="h3"
            class="text-primary"
          />
        </div>
      </div>
    </template>
  </ui-parts-form-success-box>
</template>
